var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.elemId } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.selectorTitleLabel,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "no-close-on-esc": _vm.isCellEditing || _vm.editorShow,
            "modal-class": [_vm.elemId],
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.close")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.selectorShow,
            callback: function ($$v) {
              _vm.selectorShow = $$v
            },
            expression: "selectorShow",
          },
        },
        [
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-12 px-0" },
                [
                  Array.isArray(_vm.entities)
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "field-value",
                            label: _vm.$t("fields.entity"),
                          },
                        },
                        [
                          _c(
                            "b-dropdown",
                            {
                              staticClass: "custom-dropdown value-dropdown",
                              attrs: { lazy: "", text: _vm.entity },
                            },
                            _vm._l(_vm.entities, function (item, index) {
                              return _c(
                                "b-dropdown-item",
                                {
                                  key: index,
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEntityClick(item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "has-custom-fields":
                                          _vm.inUse !== null &&
                                          _vm.inUse[item.value],
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.value) + " ")]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
          _vm.alertObj.msg != null
            ? _c("AlertFeedback", {
                attrs: {
                  msg: _vm.alertObj.msg,
                  details: _vm.alertObj.msgDetails.list,
                  detailTitle: _vm.alertObj.msgDetails.title,
                  alertState: _vm.alertObj.state,
                },
                on: { resetAlert: _vm.resetAlert },
              })
            : _vm._e(),
          _vm.allowManage && _vm.entity != null
            ? _c(
                "PriorityNavigation",
                {
                  staticClass: "grid-toolbar border",
                  attrs: {
                    closeDropdown: _vm.closePriorityNavDropdown,
                    forceRefresh: _vm.forcePriorityNavRefresh,
                  },
                  on: {
                    "update:forceRefresh": function ($event) {
                      _vm.forcePriorityNavRefresh = $event
                    },
                    "update:force-refresh": function ($event) {
                      _vm.forcePriorityNavRefresh = $event
                    },
                  },
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_ADD_${_vm.elemId}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openEditor(true)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                style: { color: "var(--grid-toolbar-button)" },
                                attrs: { icon: ["far", "plus"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_ADD_${_vm.elemId}`,
                            triggers: "hover",
                            placement: "top",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.add")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_EDIT_${_vm.elemId}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { disabled: _vm.disableEdit },
                              on: {
                                click: function ($event) {
                                  return _vm.openEditor(false)
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen-to-square"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_EDIT_${_vm.elemId}`,
                            triggers: "hover",
                            placement: "top",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.edit")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c(
                        "span",
                        { attrs: { id: `BTN_DELETE_${_vm.elemId}` } },
                        [
                          _c(
                            "b-btn",
                            {
                              attrs: { disabled: _vm.disableDelete },
                              on: { click: _vm.rowDelete },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "trash-can"] },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: `BTN_DELETE_${_vm.elemId}`,
                            triggers: "hover",
                            placement: "top",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    {
                      on: _vm._d({ mouseleave: _vm.onFilterLeave }, [
                        _vm.filterMouseEnterEvent,
                        _vm.onFilterOver,
                      ]),
                    },
                    [
                      !_vm.filterPinned
                        ? _c(
                            "b-dropdown",
                            {
                              ref: "filter",
                              staticClass: "action-bar-dropdown text-filter",
                              attrs: {
                                id: `BTN_FILTER_${_vm.elemId}`,
                                "toggle-class": "text-decoration-none",
                                "no-caret": "",
                              },
                              on: { hide: _vm.onFilterTextDropdownHide },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function () {
                                      return [
                                        _c("font-awesome-icon", {
                                          class:
                                            _vm.filterText !== ""
                                              ? "active"
                                              : "",
                                          attrs: {
                                            icon: [
                                              "far",
                                              "file-magnifying-glass",
                                            ],
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                3499512239
                              ),
                            },
                            [
                              _c(
                                "b-dropdown-form",
                                {
                                  staticClass: "filter-padding",
                                  on: {
                                    submit: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "search-input" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: _vm.$t("task.filter"),
                                        },
                                        on: {
                                          focus: function ($event) {
                                            _vm.filterTextFocus = true
                                          },
                                          blur: function ($event) {
                                            _vm.filterTextFocus = false
                                          },
                                          keypress: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            )
                                              return null
                                            return _vm.onFilterSubmit.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.filterText,
                                          callback: function ($$v) {
                                            _vm.filterText = $$v
                                          },
                                          expression: "filterText",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id: `BTN_CLEAR_FILTER_${_vm.elemId}`,
                                                size: "sm",
                                              },
                                              on: { click: _vm.onFilterClear },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "xmark"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target: `BTN_CLEAR_FILTER_${_vm.elemId}`,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.clear")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id: `BTN_SEARCH_FILTER_${_vm.elemId}`,
                                                size: "sm",
                                              },
                                              on: { click: _vm.onFilterSubmit },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: [
                                                    "far",
                                                    "magnifying-glass",
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target: `BTN_SEARCH_FILTER_${_vm.elemId}`,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("button.search")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              staticClass:
                                                "search-append search-append-bg",
                                              attrs: {
                                                id: `BTN_PIN_FILTER_${_vm.elemId}`,
                                                size: "sm",
                                              },
                                              on: { click: _vm.onPinFilter },
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                attrs: {
                                                  icon: ["far", "thumbtack"],
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-popover",
                                            {
                                              attrs: {
                                                target: `BTN_PIN_FILTER_${_vm.elemId}`,
                                                triggers: "hover",
                                                placement: "top",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "button.pinOnToolbar"
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _vm.filterPinned
                        ? _c(
                            "b-input-group",
                            { staticClass: "search-input" },
                            [
                              _c("b-form-input", {
                                attrs: { placeholder: _vm.$t("task.filter") },
                                on: {
                                  focus: function ($event) {
                                    _vm.filterTextFocus = true
                                  },
                                  blur: function ($event) {
                                    _vm.filterTextFocus = false
                                  },
                                  keypress: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.onFilterSubmit.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.filterText,
                                  callback: function ($$v) {
                                    _vm.filterText = $$v
                                  },
                                  expression: "filterText",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "search-append search-append-bg",
                                      attrs: {
                                        id: `BTN_CLEAR_FILTER_${_vm.elemId}`,
                                        size: "sm",
                                      },
                                      on: { click: _vm.onFilterClear },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: "times" },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: `BTN_CLEAR_FILTER_${_vm.elemId}`,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.clear")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "search-append search-append-bg",
                                      attrs: {
                                        id: `BTN_SEARCH_FILTER_${_vm.elemId}`,
                                        size: "sm",
                                      },
                                      on: { click: _vm.onFilterSubmit },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: ["far", "magnifying-glass"],
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: `BTN_SEARCH_FILTER_${_vm.elemId}`,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("button.search")) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass:
                                        "search-append search-append-bg",
                                      attrs: {
                                        id: `BTN_UNPIN_FILTER_${_vm.elemId}`,
                                        size: "sm",
                                      },
                                      on: { click: _vm.onUnPinFilter },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "thumbtack"] },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: `BTN_UNPIN_FILTER_${_vm.elemId}`,
                                        triggers: "hover",
                                        placement: "top",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("button.unpinFromToolbar")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("ag-grid-vue", {
            staticClass:
              "ag-theme-balham generic-selector selector-grid-height spreadsheet",
            staticStyle: { width: "100%" },
            attrs: {
              id: "rebate-grid",
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              columnDefs: _vm.columnDefs,
              context: _vm.context,
              defaultColDef: _vm.defaultColDef,
              getRowId: (params) => params.data.name,
              rowMultiSelectWithClick: false,
              rowSelection: "multiple",
              serverSideInfiniteScroll: true,
              sideBar: false,
              suppressContextMenu: "",
              suppressDragLeaveHidesColumns: "",
              suppressCellFocus: false,
              singleClickEdit: false,
              enableRangeSelection: true,
              suppressMultiSort: "",
              suppressRowClickSelection: "",
              enableFillHandle: false,
              fillOperation: _vm.fillOperation,
              fillHandleDirection: "xy",
              processCellForClipboard: _vm.processCellForClipboard,
              processCellFromClipboard: _vm.processCellFromClipboard,
              navigateToNextCell: _vm.navigateToNextCell,
              tabToNextCell: _vm.tabToNextCell,
              rowData: _vm.rowData,
              noRowsOverlayComponent: "noRowsOverlay",
              noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
            },
            on: {
              "grid-ready": _vm.onGridReady,
              "cell-key-down": _vm.onCellKeyDown,
              "paste-start": _vm.onPasteStart,
              "paste-end": _vm.onPasteEnd,
              "cell-focused": _vm.cellFocused,
              "cell-editing-started": _vm.onCellEditingStarted,
              "cell-editing-stopped": _vm.onCellEditingStopped,
            },
          }),
        ],
        1
      ),
      _vm.allowManage && _vm.editorShow
        ? _c("FieldModal", {
            attrs: {
              entity: _vm.entity,
              id: _vm.entityId,
              entities: _vm.propertyList[_vm.entity].split(","),
              show: _vm.editorShow,
              title: _vm.editorTitleLabel,
              appendAfterOptions: _vm.editorAppendAfterOptions,
            },
            on: {
              "update:show": function ($event) {
                _vm.editorShow = $event
              },
              success: _vm.editorSuccess,
            },
          })
        : _vm._e(),
      _c("InProgressModal", {
        attrs: {
          show: _vm.inProgressShow,
          label: _vm.inProgressLabel,
          isStopable: _vm.inProgressStoppable,
        },
        on: {
          "update:show": function ($event) {
            _vm.inProgressShow = $event
          },
        },
      }),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("entity_selector.confirm_deletion_title"),
            "content-class": "entity-delete-modal shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            hidden: function ($event) {
              _vm.ecConfirmDeleteShow = false
            },
            ok: _vm.ecConfirmDeleteOk,
            cancel: _vm.ecConfirmDeleteCancel,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ ok, cancel }) {
                return [
                  _vm.ecShowApplyAllCheckbox
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.entityCol.applyAll,
                            callback: function ($$v) {
                              _vm.$set(_vm.entityCol, "applyAll", $$v)
                            },
                            expression: "entityCol.applyAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.ecConfirmDeleteShow,
            callback: function ($$v) {
              _vm.ecConfirmDeleteShow = $$v
            },
            expression: "ecConfirmDeleteShow",
          },
        },
        [_c("p", [_vm._v(_vm._s(_vm.ecConfirmDeleteStatement))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }