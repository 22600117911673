<template>  
    <ag-grid-vue  class="ag-grid ag-theme-balham"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          pagination
          :paginationPageSize="1000"
          :rowData="rowData"
          rowModelType="serverSide"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          >
    </ag-grid-vue>
</template>

<script>
import { taskService } from '@/services';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import LinkCellRenderer from '@/components/Aggrid/CellRenderer/Link';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      const p = self.buildParams(params);
      if (self.canView('TASK') && self.canView('PROJECT')) {
        taskService.listHomeDashboard(p, true).then((response) => {
          params.successCallback(response.data, response.data.length);
          if (response.data.length == 0) {
            self.gridApi.showNoRowsOverlay();
          } else {
            self.gridApi.hideOverlay();
            self.gridColumnApi.autoSizeColumn('name', false);
          }
        })
        .catch(function(error) {
          console.error(error); // eslint-disable-line no-console
          params.successCallback([], self.totalRecords);
          self.gridApi.showNoRowsOverlay();
        });
      }
      else {
        self.gridApi.showNoRowsOverlay();
        params.successCallback([], self.totalRecords);
      }
    }
  }
}

export default {
  name: 'ActiveTaskList',
  components: {
    'ag-grid-vue': AgGridVue,
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    dateTimeCellRenderer: DateTimeCellRenderer,
    percentageCellRenderer: PercentageCellRenderer,
    linkCellRenderer: LinkCellRenderer,
    /* eslint-enable vue/no-unused-components */
  },
  data: function() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onGridSizeChanged: function(event) {
        self.gridColumnApi.autoSizeColumn('name', false);
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('active_task.grid.name'),
        field: 'name',
        flex: 1,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.name;
          },
          clicked: function(field) {
            self.$emit('editTask', {taskId: this.data.uuId, projectId: this.data.projectId, callback: self.reloadGrid});
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if(nodeA.group == nodeB.group) {
            if (valueA === null && valueB === null) {
              return 0;
            }
            if (valueA === null) {
              return -1;
            }
            if (valueB === null) {
              return 1;
            }
            return nodeA.data.name.toLowerCase().localeCompare(nodeB.data.name.toLowerCase());
          } else if(nodeA.group) {
            return 1;
          } else if(nodeB.group) {
            return -1;
          }
        }
      },
      {
        headerName: this.$t('active_task.grid.project'),
        field: 'project',
        flex: 1,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.project;
          },
          clicked: function(field) {
            self.$router.push('/projects/summary/'+this.data.projectId);
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if(nodeA.group == nodeB.group) {
            if (valueA === null && valueB === null) {
              return 0;
            }
            if (valueA === null) {
              return -1;
            }
            if (valueB === null) {
              return 1;
            }
            return nodeA.data.project.toLowerCase().localeCompare(nodeB.data.project.toLowerCase());
          } else if(nodeA.group) {
            return 1;
          } else if(nodeB.group) {
            return -1;
          }
        }
      },
      {
        headerName: this.$t('active_task.grid.path'),
        sortable: false,
        field: 'path',
        flex: 2,
      },
      {
        headerName: this.$t('active_task.grid.start'),
        field: 'startTime',
        sort: 'asc',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.end'),
        field: 'endTime',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_task.grid.progress'),
        field: 'progress',
        minWidth: 50,
        maxWidth: 100,
        cellRenderer: 'percentageCellRenderer'
      },
    ];
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      minWidth: 100,
      menuTabs: []
    };
    this.context = {
      componentParent: self
    };
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>No active tasks</span>`;
    },
    companyrule() {
      if (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds) {
        const companies = this.$store.state.company.filterIds;
        const companyrule = ['PROJECT.COMPANY.uuId', 'within', companies.join('|')];
        return companyrule
      }
      return null;
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };
      updateData();
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.companyrule = this.companyrule;
      return params;
    },
    reloadGrid() {
      this.gridApi.refreshServerSide({purge: true});
    }
  }
}
</script>
